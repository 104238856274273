import { Styles } from '@chakra-ui/theme-tools';

const styles: Styles = {
  global: () => ({
    html: {
      scrollPaddingTop: '80px', // Header height (60px) plus some margin, applies when scrolling elements into view.
    },
    body: {
      overflow: 'hidden',
      fontFamily: 'DM Sans, sans-serif',
    },
  }),
};

export default styles;
