import React, { Suspense, useEffect } from 'react';

import { createRoot } from 'react-dom/client';
import { Auth } from 'aws-amplify';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import userflow from 'userflow.js';
import { Spinner } from '@chakra-ui/react';

import { API } from '@m3ter-com/m3ter-api';
import { LoadingScreen, ThemeProvider } from '@m3ter-com/ui-components';
import { setupI18n } from '@m3ter-com/console-core/hooks';

import config, { getEnvironmentName, isSentryEnabled } from '@/config';
import { registerWidgets } from '@/modules/widgets';
import { AppRoutesContextProvider } from '@/routes/context';
import { DocumentTitle } from '@/components/common/breadcrumbs/DocumentTitle';

import setupAmplify from './amplify';
import setupStore from './store';
import setupRoutes from './routes';
import setupQueryClient from './query-client';

setupAmplify(config);
const queryClient = setupQueryClient();

// Configure our API client.
API.configure({
  endpoint: config.apiEndpoint,
  ingestEndpoint: config.ingestEndpoint,
  headers: async () => ({
    Authorization: `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  }),
});

// Initialise Sentry when not running locally and not on alpha.
if (isSentryEnabled()) {
  init({
    dsn: 'https://6ea13eccf2ec49bc90c30008e92711c1@o1126193.ingest.sentry.io/6166928',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.2,
    environment: getEnvironmentName(),
  });
}

setupI18n();

// Router

const routes = setupRoutes();
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);

// Store

const store = setupStore({ router });
registerWidgets(store);

// Expose redux store when running in Cypress
// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

// Initialise Userflow. The user identity is set up in userflow.saga.ts
userflow.init(config.userflow.token);

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AppRoutesContextProvider routes={routes}>
            <DocumentTitle siteTitle="m3ter" />
            <Suspense fallback={<Spinner />}>
              <RouterProvider
                router={router}
                fallbackElement={<LoadingScreen />}
              />
            </Suspense>
          </AppRoutesContextProvider>
        </ThemeProvider>
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
