import { Id } from '@m3ter-com/m3ter-api';

import { isProd } from '@/config';

import { Feature } from './flags';

const ASSEMBLED_HQ_ORG_IDS = [
  'ebe253e8-5b32-42ca-ad46-7184bef6ca73',
  '1f1292a8-28e4-447c-8509-9e1295703b44',
  '998d9758-ce74-4d8c-8d5c-66e4ac493c34',
];

const M3TER_REPORTING_ORG_IDS = [
  '965b4898-64e5-4ff9-860b-38b902d9c750',
  'df98936a-4c52-4677-87f9-8d86d7e9300d',
];

const M3TER_TRANSACTION_ORG_IDS = [
  '39d40463-0ae3-4bf8-acb0-9fa26f3e11d6',
  'eaf450ef-3eec-4355-8ab9-ac6f88a808b4',
  '83f09493-b628-48fa-bfc4-76eaf8e3e2b6',
  '396d788d-5174-4e8b-9d69-a41f4671fc33',
  '257065e1-5487-45d4-88ad-969bbad82564',
  'dbe43c6a-162e-4d5d-b306-b6180db496e5',
];

const SIFT_ORG_IDS = [
  '22207559-2ea6-430b-b76b-5e25769047c9',
  'b5349f0a-a540-4f03-b274-5cddd2f84c95',
  '18483df5-bdfa-452f-ad7e-5435a4aa3261',
];

const TRUELAYER_ORG_IDS = [
  '2b4f083f-0ec8-4ae2-9848-246de51ce42e',
  '5db4f934-c6ec-49d2-ad4b-0930246896c6',
  '49267f90-67f3-46cf-ab40-063c78f6ba71',
  '1935b25d-dd7d-46bc-b100-f561d1487120',
  '50574f81-f061-4a1f-ad69-f727d529ea6f',
  'f0c3b99f-0b3a-4a7f-a9bf-0f9085807967',
  'd39f8e76-11e4-45c5-ab38-02416140a706',
];

// This sets the default status of the flag per feature.
// If a feature is released to all orgs it would be true.
// If a feature is restricted to certain orgs it would be false
// and overriden in the `getFeatures` method, based on org ID.
const defaultFeatureFlags: Record<Feature, boolean> = {
  [Feature.CompoundAggregations]: true,
  [Feature.IntegrationDestinations]: true,
  [Feature.Notifications]: true,
  [Feature.Reports]: false,
  [Feature.Statements]: true,
  [Feature.TransactionPricing]: false,
  [Feature.Counters]: true,
};

// This sets whether or not the feature should be available
// to all orgs on non-prod.
const preProdFeatures: Record<Feature, boolean> = {
  [Feature.CompoundAggregations]: true,
  [Feature.IntegrationDestinations]: true,
  [Feature.Notifications]: true,
  [Feature.Reports]: true,
  [Feature.Statements]: true,
  [Feature.TransactionPricing]: false,
  [Feature.Counters]: true,
};

const isFeatureAllowedForOrg = (feature: Feature, orgId: string): boolean => {
  switch (feature) {
    case Feature.Reports:
      return (
        ASSEMBLED_HQ_ORG_IDS.includes(orgId) ||
        SIFT_ORG_IDS.includes(orgId) ||
        M3TER_REPORTING_ORG_IDS.includes(orgId)
      );
    case Feature.TransactionPricing:
      return (
        TRUELAYER_ORG_IDS.includes(orgId) ||
        M3TER_TRANSACTION_ORG_IDS.includes(orgId)
      );
    default: {
      return false;
    }
  }
};

export const getFeatures = (orgId: Id) =>
  Object.keys(defaultFeatureFlags).filter(
    (feature) =>
      (!isProd() && preProdFeatures[feature as Feature]) ||
      isFeatureAllowedForOrg(feature as Feature, orgId) ||
      defaultFeatureFlags[feature as Feature]
  );
