import React, { useMemo } from 'react';

import { Alert, ButtonProps } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';

const LazyDashboard = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "Dashboard" */ '@/components/features/dashboard/Dashboard'
  ).then((module) => ({ default: module.Dashboard }))
);

export const OrganizationIndexRoute: React.FC = () => {
  const { t } = useTranslation();

  const actionButtonProps = useMemo<ButtonProps>(
    () => ({
      as: 'a',
      size: 'sm',
      href: 'https://console.m3ter.com',
    }),
    []
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:dashboard')} />
      <Alert
        mb={2}
        status="info"
        alertTitle={t('features:preview.previewBannerTitle')}
        actionButtonText={t('features:preview.exploreNewConsole')}
        actionButttonProps={actionButtonProps}
      >
        {t('features:preview.previewBannerDescription')}
      </Alert>
      <LazyDashboard />
    </React.Fragment>
  );
};
