import React, { useCallback, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  FormLabel,
  Heading,
  HStack,
  Link,
  Spinner,
  Flex,
  Switch,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  StackDivider,
  Divider,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

import {
  DataType,
  Bill,
  BillStatus,
  IntegrationRunViewEntityTypes,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, KeyValue, Confirmation } from '@m3ter-com/ui-components';

import { CrudQueryParams } from '@/types/routes';
import { AppError } from '@/types/errors';

import { getReference } from '@/util/billing';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useAppQuery from '@/hooks/data/useAppQuery';
import useCurrencies from '@/hooks/util/useCurrencies';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import useQueryString from '@/hooks/navigation/useQueryString';
import useBillView from '@/hooks/features/billing/useBillView';
import useBillStatements from '@/hooks/features/billing/useBillStatements';
import useIntegrationRunView from '@/hooks/features/integrations/useIntegrationRunView';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { AddressDetails } from '@/components/common/data/AddressDetails';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink';
import { ExternalInvoiceLink } from '@/components/features/billing/ExternalInvoiceLink';
import {
  GenerateStatementsFormValues,
  GenerateStatementsModal,
} from '@/components/features/billing/GenerateStatementsModal';
import { CurrencyConversionsSummary } from '@/components/features/organization/OrganizationConfigSummary/CurrencyConversionsSummary';
import { ErrorAlerts } from '@/components/common/errors/ErrorAlerts';

import { BillTable } from './BillTable';

export interface BillDetailsProps {
  data: Bill;
}

export const BillDetails: React.FC<BillDetailsProps> = ({ data }) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { formatCurrency } = useCurrencies();

  const billTotal = useMemo(
    () => formatCurrency(data.billTotal, data.currency),
    [data, formatCurrency]
  );

  const {
    isOpen: zeroQuantityLineItemsHidden,
    onToggle: onHideZeroQuantityLineItems,
  } = useDisclosure();

  const hasLineItemsWithZeroQuantity = useMemo(
    () =>
      data.lineItems.some(
        (lineitem) => lineitem.units !== undefined && lineitem.units === 0
      ),
    [data.lineItems]
  );

  const pathParams = useOrgPathParams();
  const {
    data: accountData,
    error: accountError,
    isLoading: isLoadingAccount,
  } = useAppQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.Account,
      id: data.accountId,
      pathParams,
    })
  );

  const {
    isBillLocked,
    isUpdatingBill,
    isRecalculatingBill,
    error,
    approveBill,
    lockBill,
    recalculateBill,
  } = useBillView(data);

  const { latestIntegrationRunData } = useIntegrationRunView(
    IntegrationRunViewEntityTypes.Bill,
    data.id
  );

  const {
    generate,
    downloadCsv,
    downloadJson,
    isGenerating,
    generateError,
    isGenerateStatementsModalOpen,
    openGenerateStatementsModal,
    closeGenerateStatementsModal,
  } = useBillStatements(data);

  const onGenerateStatementsSubmit = useCallback(
    (values: GenerateStatementsFormValues) => {
      generate(values.generateCsvStatement);
      closeGenerateStatementsModal();
    },
    [generate, closeGenerateStatementsModal]
  );

  const isChildAccount = !!accountData?.parentAccountId;

  const areButtonsDisabled =
    isBillLocked || isRecalculatingBill || isUpdatingBill;

  const navigate = useNavigate();
  const { basePath } = useCrudContext<Bill>();
  const { returnPath = basePath } = useQueryString<CrudQueryParams>();

  const goBack = useCallback(() => {
    navigate(returnPath);
  }, [navigate, returnPath]);

  const { t } = useTranslation();
  const { toLongDateTime, toLongDate } = useDateFormatter();

  const errors = useMemo<Array<AppError | undefined>>(
    () => [
      error,
      ...(accountError
        ? [
            {
              ...accountError,
              message: t('errors:account.accountLoadingError').replace(
                '{error}',
                accountError.message
              ),
            },
          ]
        : []),
      generateError,
    ],
    [accountError, error, generateError, t]
  );

  if (isLoadingAccount) {
    return <Spinner />;
  }

  const reference = getReference(data);

  const externalInvoiceDate = toLongDate(data.externalInvoiceDate);

  return (
    <React.Fragment>
      <BreadcrumbItem title={externalInvoiceDate} />
      <HStack justify="space-between" mb={8}>
        <Box>
          <Button aria-label={t('common:goBack')} onClick={goBack}>
            {t('common:goBack')}
          </Button>
        </Box>
        <ButtonGroup>
          <Button
            isDisabled={areButtonsDisabled}
            as={CrudCreateLink}
            addReturnPath
            dataType={DataType.CreditLineItem}
          >
            {t('features:billing.addCredit')}
          </Button>
          <Button
            isDisabled={areButtonsDisabled}
            as={CrudCreateLink}
            addReturnPath
            dataType={DataType.DebitLineItem}
          >
            {t('features:billing.addDebit')}
          </Button>
          <Button
            isDisabled={
              areButtonsDisabled || data.status === BillStatus.Approved
            }
            onClick={approveBill}
          >
            {t('features:billing.approveBill')}
          </Button>
          <Tooltip
            label={
              isChildAccount && !isBillLocked
                ? t('features:billing.childAccountBillCalculationWarning')
                : ''
            }
          >
            <Button
              aria-label={t('features:billing.recalculateBill')}
              isDisabled={areButtonsDisabled || isChildAccount}
              isLoading={isRecalculatingBill}
              onClick={recalculateBill}
            >
              {t('features:billing.recalculateBill')}
            </Button>
          </Tooltip>
          <Confirmation
            message={t('features:billing.lockBillConfirmation')}
            trigger={
              <Button
                aria-label={t('features:billing.lockBill')}
                isDisabled={
                  areButtonsDisabled || data.status === BillStatus.Pending
                }
              >
                {t('features:billing.lockBill')}
              </Button>
            }
            onConfirm={lockBill}
          />
          {isFeatureEnabled(Feature.Statements) && (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<FaChevronDown />}
                isDisabled={
                  isGenerating || isRecalculatingBill || isUpdatingBill
                }
              >
                {t('common:statements')}
                {isGenerating && <Spinner size="xs" ml={2} />}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={openGenerateStatementsModal}>
                  {t('features:statements.generateStatements')}
                </MenuItem>
                <MenuItem
                  onClick={downloadJson}
                  isDisabled={isGenerating || !data.jsonStatementGenerated}
                >
                  {t('features:statements.downloadJson')}
                </MenuItem>
                <MenuItem
                  isDisabled={isGenerating || !data.csvStatementGenerated}
                  onClick={downloadCsv}
                >
                  {t('features:statements.downloadCsv')}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </ButtonGroup>
      </HStack>
      <ErrorAlerts errors={errors} />
      <DetailsCard
        data={data}
        dataType={DataType.Bill}
        showAuditData
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <Box gridRow="1/5">
              {!!accountData && (
                <KeyValue
                  label={t('features:billing.billTo')}
                  value={
                    <Box>
                      <Link
                        as={CrudDetailsLink}
                        dataType={DataType.Account}
                        id={accountData.id}
                      >
                        {accountData.name}
                      </Link>
                      {!!accountData.address && (
                        <AddressDetails data={accountData.address} />
                      )}
                    </Box>
                  }
                />
              )}
            </Box>
            <KeyValue label={t('forms:labels.reference')} value={reference} />
            <KeyValue
              label={t('forms:labels.currency')}
              value={data.currency}
            />
            <KeyValue
              label={t('features:billing.externalInvoiceReference')}
              value={
                <ExternalInvoiceLink
                  bill={data}
                  latestIntegrationRunData={latestIntegrationRunData}
                />
              }
            />
            <KeyValue
              label={t('forms:labels.status')}
              value={
                isBillLocked
                  ? t('forms:labels.locked')
                  : t(`features:billing.status.${data.status}`)
              }
            />
            {isChildAccount && (
              <KeyValueReferenceLink
                label={t('forms:labels.parentAccount')}
                dataType={DataType.Account}
                id={accountData.parentAccountId!} // isChildAccount validates the parentAccountId.
                accessor="name"
              />
            )}
            <KeyValue
              label={t('forms:labels.purchaseOrderNumber')}
              value={data.purchaseOrderNumber || '-'}
            />
            <KeyValue
              label={t('forms:labels.lastCompletedIntegrationRunDate')}
              value={
                latestIntegrationRunData?.dtCompleted ? (
                  <React.Fragment>
                    <Link
                      as={NamedLink}
                      name="integrations.runs"
                      queryParams={{
                        entityId: data.id,
                        entityType: IntegrationRunViewEntityTypes.Bill,
                      }}
                    >
                      {toLongDateTime(latestIntegrationRunData.dtCompleted)}
                    </Link>
                    <Text>
                      {latestIntegrationRunData?.status && (
                        <span>
                          (
                          {t(
                            `features:integrations.integrationRunStatus.${latestIntegrationRunData.status}`
                          )}
                          )
                        </span>
                      )}
                    </Text>
                  </React.Fragment>
                ) : (
                  t('common:na')
                )
              }
            />
            <KeyValue
              label={t('forms:labels.lastCalculatedDate')}
              value={toLongDateTime(data.lastCalculatedDate)}
            />
          </SimpleGrid>
        }
      >
        <Card>
          <CardHeader>
            <Flex justifyContent="space-between">
              <Heading size="md">
                <HStack spacing={4} divider={<StackDivider />}>
                  <Text>{`${t('common:total')}: ${billTotal}`}</Text>
                </HStack>
              </Heading>
              {hasLineItemsWithZeroQuantity && (
                <HStack>
                  <FormLabel htmlFor="hide-line-items-switch" mb="0">
                    {t('features:billing.hideZeroQuantityLineItems')}
                  </FormLabel>
                  <Switch
                    id="hide-line-items-switch"
                    isChecked={zeroQuantityLineItemsHidden}
                    onChange={onHideZeroQuantityLineItems}
                  />
                </HStack>
              )}
            </Flex>
          </CardHeader>
          <CardBody>
            <BillTable
              bill={data}
              zeroQuantityLineItemsHidden={zeroQuantityLineItemsHidden}
            />
            <Divider mb={4} />
            <Box>
              <Heading as="h3" size="sm" mb={2}>
                {t('features:organizations.currencyConversions')}
              </Heading>
              {data.currencyConversions &&
              data.currencyConversions.length > 0 ? (
                <CurrencyConversionsSummary
                  currencyConversions={data.currencyConversions}
                />
              ) : (
                <Text>{t('errors:organizations.noCurrencyConversions')}</Text>
              )}
            </Box>
          </CardBody>
        </Card>
      </DetailsCard>
      <GenerateStatementsModal
        isOpen={isGenerateStatementsModalOpen}
        onClose={closeGenerateStatementsModal}
        onSubmit={onGenerateStatementsSubmit}
      />
    </React.Fragment>
  );
};
